import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import {
	getNewestArticles,
	loadTextsByNamespace,
} from "../../../../server/helper/ssrHelper";
import config from "../../../config/main.config";

import ChateauImageView from "../../ChateauImageView/ChateauImageView";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import EditTextComponent from "../../../components/editText/EditTextComponent";
import EditImageComponent from "../../../components/editImage/EditImageComponent";
import EditHTMLComponent from "../../../components/editHTML/EditHTMLComponent";
import EditLinkComponent from "../../../components/editLink/EditLinkComponent";
import BlogPostPreview from "../../../pages/blog/BlogPostPreview";
import EditButtonComponent from "../../../components/editButton/EditButtonComponent";
import { useWindowSize } from "../../../components/editImage/EditImageComponent/utils";
import { Modal } from "semantic-ui-react";
import * as utils from "../../../components/editImage/EditImageComponent/utils";

const namespace = "Home";

const Home = (props) => {
	const { i18n } = props;
	const [newestArticles, setNewestArticles] = useState([]);
	const [showImageModal, setShowImageModal] = useState(false);
	const [imageId, setImageId] = useState("");
	const [imageSrc, setImageSrc] = useState("");
	let width = useWindowSize().width;
	useEffect((_) => {
		getNewestArticles(config.BASE_URL, 3).then((articles) => {
			setNewestArticles(articles.articles);
		});
	}, []);

	useEffect(
		(_) => {
			utils.fetchFrontendIdImage(imageId).then((image) => {
				setImageSrc(image.link);
			});
		},
		[imageId],
	);
	const ImageModal = () => {
		return (
			<Modal
				open={showImageModal}
				basic
				onClose={() => {
					setShowImageModal(false);
					setImageId("");
					setImageSrc("");
				}}
			>
				<Modal.Content>
					<img
						src={config.BASE_URL_IMAGES + "all-images/" + imageSrc}
						alt={imageId}
					/>
				</Modal.Content>
			</Modal>
		);
	};

	return (
		<div className={"grid ui"}>
			<div className={"row centered"}>
				<div
					className={
						"sixteen wide mobile sixteen wide tablet seven wide computer column mb-2-mobile"
					}
				>
					<div className={"text-center"}>
						<img
							src={config.BASE_URL_IMAGES + "logo.png"}
							alt={"LOGO"}
							className={"home-front-image"}
						/>
					</div>
					<section className={"home-header-container"}>
						<h1 className={"home-header"}>
							<EditHTMLComponent
								id={`${namespace}-header-1`}
								namespace={namespace}
							/>
						</h1>
						<h4 className={"home-sub-header"}>
							<EditHTMLComponent
								id={`${namespace}-sub-header-1`}
								namespace={namespace}
							/>
						</h4>
						<a
							href={`mailto:info@chateauleval.fr?subject=Anfrage&body=${i18n.t(
								"component:descriptions.mail",
								{ room: "_____" },
							)}`}
						>
							<EditButtonComponent
								style={{ marginTop: "3rem" }}
								namespace={namespace}
								id={`${namespace}-button-1`}
							/>
						</a>
					</section>
				</div>
				<div className={"sixteen wide tablet nine wide computer column"}>
					<img
						alt={"backgroundImage"}
						src={config.BASE_URL_IMAGES + "chateau.jpg"}
						className={"home-image"}
					/>
				</div>
			</div>
			<div className={"row centered row-padding-bottom"}>
				<div
					className={
						"fifteen wide mobile three wide tablet seven wide computer column text-center margin-auto"
					}
				>
					<EditImageComponent
						style={{ width: "300px", borderRadius: "50%" }}
						id={`${namespace}-image-2`}
					/>
					<h5>
						<EditTextComponent
							id={`${namespace}-sub-header-3`}
							namespace={namespace}
						/>
					</h5>
					<EditHTMLComponent
						id={`${namespace}-sub-text-1`}
						namespace={namespace}
					/>
				</div>
				<div
					className={
						"fifteen wide mobile twelve wide tablet nine wide computer column"
					}
				>
					<Menu secondary className={"menu-home"}>
						<Menu.Item className={"menu-home-item padding-left-null"}>
							<EditLinkComponent
								id={`${namespace}-link-2`}
								namespace={namespace}
							/>
						</Menu.Item>
						<Menu.Item className={"menu-home-item"}>
							<EditLinkComponent
								id={`${namespace}-link-3`}
								namespace={namespace}
							/>
						</Menu.Item>
						{/*<Menu.Item className={'menu-home-item'}>
                        <EditLinkComponent
                            id={`${namespace}-link-1`} namespace={namespace}/>
                    </Menu.Item>*/}

						<Menu.Item className={"menu-home-item-last"}>
							<EditLinkComponent
								id={`${namespace}-link-4`}
								namespace={namespace}
							/>
						</Menu.Item>
					</Menu>
					<div>
						<h1>
							<EditTextComponent
								id={`${namespace}-header-4`}
								namespace={namespace}
							/>
						</h1>
						<h5>
							<EditTextComponent
								id={`${namespace}-sub-header-2`}
								namespace={namespace}
							/>
						</h5>
						<p>
							<EditHTMLComponent
								style={width <= 1701 ? {} : { maxWidth: "80%" }}
								id={`${namespace}-text-1`}
								namespace={namespace}
							/>
						</p>
						<EditButtonComponent
							namespace={namespace}
							id={`${namespace}-button-2`}
						/>
					</div>
				</div>
			</div>
			<div
				className={"row centered row-padding-top row-padding-bottom"}
				style={{ background: "#373430" }}
			>
				<div
					className={"hide-div-mobile"}
					style={{
						width: "700px",
						height: "300px",
						position: "absolute",
						border: "3px solid",
						borderColor: "#9fc058",
						top: 0,
						marginTop: "12rem",
					}}
				/>
				<div className={"grid ui"} style={{ maxWidth: "1400px" }}>
					<div className={"row centered"}>
						<div
							className={"sixteen wide column"}
							style={{ display: "flex", marginBottom: "3rem" }}
						>
							<h1
								style={{
									color: "white",
									width: "fitContent",
									margin: "auto",
									background: "#373430",
									paddingLeft: "20px",
									paddingRight: "20px",
								}}
							>
								<EditTextComponent
									id={`${namespace}-header-5`}
									namespace={namespace}
								/>
							</h1>
						</div>
					</div>
					<div
						className={"row centered"}
						style={{ backgroundColor: "#373430" }}
					>
						<div className={"four wide computer fourteen wide mobile column"}>
							<section className={"home-section"}>
								<div className={"home-section-image"}>
									<EditImageComponent
										style={{ aspectRatio: "16/10" }}
										id={`${namespace}-image-3`}
									/>
								</div>
								<h5 style={{ minHeight: "82px" }}>
									<EditTextComponent
										id={`${namespace}-sub-header-4`}
										namespace={namespace}
									/>
								</h5>
								<p className="offer-text">
									<EditHTMLComponent
										id={`${namespace}-text-2`}
										namespace={namespace}
									/>
								</p>
							</section>
						</div>
						<div className={"four wide computer fourteen wide mobile column"}>
							<section className={"home-section"}>
								<div className={"home-section-image"}>
									<EditImageComponent
										style={{ aspectRatio: "16/10" }}
										id={`${namespace}-image-4`}
									/>
								</div>
								<h5 style={{ minHeight: "82px" }}>
									<EditTextComponent
										id={`${namespace}-sub-header-5`}
										namespace={namespace}
									/>
								</h5>
								<p className="offer-text">
									<EditHTMLComponent
										id={`${namespace}-text-3`}
										namespace={namespace}
									/>
								</p>
							</section>
						</div>
						<div className={"four wide computer fourteen wide mobile column"}>
							<section className={"home-section"}>
								<div className={"home-section-image"}>
									<EditImageComponent
										style={{ aspectRatio: "16/10" }}
										id={`${namespace}-image-5`}
									/>
								</div>
								<h5 style={{ minHeight: "82px" }}>
									<EditTextComponent
										id={`${namespace}-sub-header-6`}
										namespace={namespace}
									/>
								</h5>
								<p className="offer-text">
									<EditHTMLComponent
										id={`${namespace}-text-4`}
										namespace={namespace}
									/>
								</p>
							</section>
						</div>
						{/*<div className={'twelve wide computer fourteen wide mobile column text-center'}>
                        <EditButtonComponent style={{marginTop: '3rem'}} namespace={namespace}
                                             id={`${namespace}-button-4`}/>
                    </div>*/}
					</div>
				</div>
			</div>
			<div className={"sixteen wide column row-padding-top"}>
				<ChateauImageView />
			</div>
			<div
				className={"row centered"}
				style={{ background: "#373430", paddingTop: "10rem" }}
			>
				<div
					className={"hide-div-mobile"}
					style={{
						width: "700px",
						height: "100%",
						position: "absolute",
						border: "3px solid",
						borderLeft: "none",
						borderColor: "#9fc058",
						top: "13rem",
					}}
				/>
				<div
					className={"grid ui max-width"}
					style={width <= 1110 ? {} : { minWidth: "1400px" }}
				>
					<div className={"row centered"}>
						<div className={"twelve wide computer fourteen wide mobile column"}>
							<section
								style={{
									maxWidth: "350px",
									color: "white",
									backgroundColor: "#373430",
								}}
							>
								<h1 style={{ color: "white" }}>
									<EditTextComponent
										id={`${namespace}-header-6`}
										namespace={namespace}
									/>
								</h1>
								<h5>
									<EditTextComponent
										id={`${namespace}-sub-header-7`}
										namespace={namespace}
									/>
								</h5>
								<EditHTMLComponent
									id={`${namespace}-text-5`}
									namespace={namespace}
								/>
							</section>
						</div>
					</div>
				</div>
			</div>
			<div
				className={"row centered home-padding-bottom-section"}
				style={{ backgroundColor: "#373430" }}
			>
				<div className={"grid ui max-width"}>
					<div className={"row centered"}>
						<div className={"four wide computer fourteen wide mobile column"}>
							{newestArticles.length > 0 && (
								<section className={"blog-preview-container"}>
									<BlogPostPreview
										article={newestArticles[0]}
										index={0}
										as={"Compressed"}
									/>
								</section>
							)}
						</div>
						<div className={"four wide computer fourteen wide mobile column"}>
							{newestArticles.length > 1 && (
								<section className={"blog-preview-container"}>
									<BlogPostPreview
										article={newestArticles[1]}
										index={1}
										as={"Compressed"}
									/>
								</section>
							)}
						</div>
						<div className={"four wide computer fourteen wide mobile column"}>
							{newestArticles.length > 2 && (
								<section className={"blog-preview-container"}>
									<BlogPostPreview
										article={newestArticles[2]}
										index={2}
										as={"Compressed"}
									/>
								</section>
							)}
						</div>
						<div
							className={
								"twelve wide computer fourteen wide mobile column text-center"
							}
						>
							<EditButtonComponent
								style={{ marginTop: "3rem" }}
								namespace={namespace}
								id={`${namespace}-button-3`}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={"row centered max-width"} style={{ paddingTop: "10rem" }}>
				<div className={"twelve wide computer fourteen wide mobile column"}>
					<h1>
						<EditTextComponent
							id={`${namespace}-header-7`}
							namespace={namespace}
						/>
					</h1>
					{/*<p className={'hashtag'} onClick={() => navigator.clipboard.writeText("#CHATEAULEVALBRIX")}>
                    <EditHTMLComponent id={`${namespace}-sub-text-2`}
                                       namespace={namespace}/></p>*/}
				</div>
			</div>
			<div className={"row centered"}>
				<div className={"grid ui max-width"}>
					<div className={"three column row centered"}>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-9`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-9`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-10`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-10`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-11`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-11`} />
						</div>
					</div>
					<div className={"three column row centered"}>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-12`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-12`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-13`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-13`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-14`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-14`} />
						</div>
					</div>
					<div className={"three column row centered"}>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-15`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-15`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-16`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-16`} />
						</div>
						<div
							className={
								"clickable-image four wide computer fourteen wide mobile column"
							}
							onClick={() => {
								setShowImageModal(true);
								setImageId(`${namespace}-image-17`);
							}}
						>
							<EditImageComponent lazy={true} id={`${namespace}-image-17`} />
						</div>
					</div>
				</div>
				{ImageModal()}
			</div>
		</div>
	);
};

Home.fetchData = () => {
	return loadTextsByNamespace(config.BASE_URL, "Home").then((text) => {
		return text;
	});
};

export default withTranslation(["common", "component"])(Home);
