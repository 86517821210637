import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import userStore from "../../adminComponents/userManagement/userStore"
import config from "../../../config/main.config";
import {fetchAllImages, getResponsiveImagePath} from "../../../helper/util";

import {withTranslation} from "react-i18next";
import sharedConfig from "../../../../shared/sharedConfig";
import * as utils from "./utils";
import {useLocation} from "react-router-dom";
import ImageCropComponent from "../ImageCropComponent";
import history from "../../../helper/browserHistory";
import i18n from "i18next";

/*
const ImageCropComponent = loadable({
    loader: () => import(/* webpackChunkName: 'ImageCropComponent' /"../ImageCropComponent"),
    loading: () => LoaderComponent
});
*/

const EditImageComponent = (props) => {
    const {i18n, id, style, linkTo, className, lazy} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [allImages, setAllImages] = useState([]);
    const [cropModal, setCropModal] = useState(false);
    const [selectModal, setSelectModal] = useState(false);
    const [editAlt, setEditAlt] = useState(false);
    const [imageFilter, setImageFilter] = useState('');
    const [imagePath] = useState(getResponsiveImagePath(utils.useWindowSize().width));
    const [altText, setAltText] = useState('');
    const [image, setImage] = useState({link: null, alt: ''});
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);
    const search = useLocation().search;

    useEffect(() => {
        utils.fetchFrontendIdImage(id).then(image_ => {
            setAltText(image_.alt);
            setImage(image_);
        });
    }, [selectModal, modalOpen]);

    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator) && search !== "?preview");
    }, [userStore.isAdmin, userStore.isContentCreator, search]);


    return (
        <>
            {
                canEdit ?
                    <>
                        <Button className={"circular edit_button admin-button-primary"}
                                icon={"edit outline"}
                                style={{zIndex: 100}}
                                onClick={() => {
                                    setModalOpen(true);
                                }}/>
                        <Modal className={"admin-primary"}
                               open={modalOpen}
                               style={{"margin": "auto"}}>
                            <Modal.Header className={"admin-primary"}>Modal Header</Modal.Header>
                            <Modal.Content className={"admin-primary"} scrolling>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column>
                                            {
                                                image.link ?
                                                    <Button
                                                        onClick={() => {
                                                            utils.editImage(id, {link: null}, altText).then(image_ => {
                                                                setImage({...image, link: image_.link});
                                                            })
                                                        }}
                                                        attached={"top"}
                                                        className={"gallery-delete-button admin-button-warn"}>
                                                        {i18n.t("common:common.delete")}
                                                    </Button>
                                                    : null
                                            }
                                            {
                                                editAlt ?
                                                    <Form className={"admin-primary"}>
                                                        <input className={"admin-primary admin-border-primary"}
                                                               type={"text"}
                                                               onChange={(e) => setAltText(e.target.value)}
                                                               value={altText}
                                                        />
                                                        <Form.Field>
                                                            <Button.Group>
                                                                <Button className={"admin-button-primary"}
                                                                        onClick={() => {
                                                                            utils.editImage(id, image, altText).then(image_ => {
                                                                                setImage(image_);
                                                                            });
                                                                            setEditAlt(false);
                                                                        }}>
                                                                    {i18n.t("common:common.edit")}
                                                                </Button>
                                                                <Button className={"admin-button-warn"}
                                                                        onClick={() => {
                                                                            setEditAlt(false);
                                                                        }}>
                                                                    {i18n.t("common:common.cancel")}
                                                                </Button>
                                                            </Button.Group>
                                                        </Form.Field>
                                                    </Form>
                                                    : <div>
                                                        <p>{image.alt}</p>
                                                        <Button className={"admin-button-primary"}
                                                                onClick={() => {
                                                                    setEditAlt(true);
                                                                }}>
                                                            {i18n.t("common:common.edit")} alt-tag
                                                        </Button>
                                                    </div>
                                            }
                                            <img src={config.BASE_URL_IMAGES + 'all-images/' + image.link}
                                                 alt={image.alt}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Modal
                                        className={"admin-primary"}
                                        open={selectModal}
                                        style={{"margin": "auto"}}
                                        centered={true}>
                                        <Modal.Header className={"admin-primary"}>
                                            {i18n.t("common:common.select")}
                                        </Modal.Header>
                                        <Modal.Content className={"admin-primary"} scrolling>
                                            <Form style={{marginBottom: '10px'}}>
                                                <label><b>{i18n.t("common:common.search")}</b></label>
                                                <br/>
                                                <input
                                                    className={"admin-primary admin-border-primary"}
                                                    type={'text'}
                                                    placeholder={i18n.t("common:common.search")}
                                                    value={imageFilter}
                                                    onChange={(e) => {
                                                        setImageFilter(e.target.value)
                                                    }}/>
                                            </Form>
                                            <div style={{display: 'flex', marginBottom: '20px'}}>
                                                <div style={{
                                                    background: 'blueviolet',
                                                    height: '10px',
                                                    width: '10px',
                                                    margin: 'auto 10px',
                                                }}/>
                                                <p>.webp</p>
                                            </div>

                                            <Grid>
                                                <Grid.Row>
                                                    {utils.getFilteredImages(allImages, imageFilter, imagePath, id, setImage, altText, setSelectModal)}
                                                </Grid.Row>
                                            </Grid>
                                        </Modal.Content>
                                        <Modal.Actions className={"admin-primary"}>
                                            <Button className={"admin-button-warn"}
                                                    icon='delete'
                                                    onClick={() => setSelectModal(false)}/>
                                        </Modal.Actions>
                                    </Modal>
                                </Grid>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button.Group floated={"left"}>
                                    <Button className={"admin-button-basic"}
                                            onClick={() => {
                                                setSelectModal(true);
                                                fetchAllImages().then(images => {
                                                    setAllImages(images);
                                                });
                                            }}>
                                        {i18n.t("common:common.select")}
                                    </Button>
                                    <Button className={"admin-button-primary"}
                                            onClick={() =>
                                                setCropModal(true)
                                            }>
                                        {i18n.t("common:common.upload")}
                                    </Button>
                                </Button.Group>
                                <Button className={"admin-button-warn"}
                                        onClick={() => {
                                            setModalOpen(false);
                                        }}
                                >
                                    {i18n.t("common:common.close")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                        {cropModal && <ImageCropComponent cropModal={cropModal}
                                                          id={id}
                                                          altText={altText}
                                                          onClose={() => {
                                                              setCropModal(false)
                                                              utils.fetchFrontendIdImage(id).then(image => {
                                                                  setImage({
                                                                      ...image,
                                                                      name: image.name,
                                                                      link: image.link,
                                                                      alt: altText
                                                                  });
                                                              });
                                                              fetchAllImages().then(images => {
                                                                  setAllImages(images);
                                                              });
                                                          }}
                        />}
                    </>
                    : null
            }
            {image.name ? <img className={className ? className : ''}
                     onClick={_ => {
                         if(linkTo && !canEdit){
                             history.push('/' + i18n.language + '/' + linkTo)
                         }
                     }}
                     style={style ? style : {}}
                     src={config.BASE_URL_IMAGES + 'all-images/' + image.link}
                     alt={image.alt}/>
          : null}
        </>
    )
}

EditImageComponent.propTypes =
    {
        id: PropTypes.string,
        aspectRatio: PropTypes.oneOf(['4_3', '1_1', '16_9'])
    };

EditImageComponent.defaultProps =
    {
        id: Math.random().toString(),
        aspectRatio: "16_9"
    };

export default withTranslation(['common'])(EditImageComponent)