import React, {useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import EditImageComponent from "../../components/editImage/EditImageComponent";
import EditTextComponent from "../../components/editText/EditTextComponent";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import config from "../../config/main.config";
import history from "../../helper/browserHistory";
import userStore from "../../components/adminComponents/userManagement/userStore";

const namespace = 'ImageView'
const ChateauImageView = (props) => {
    const {i18n} = props;
    const [canEdit, setCanEdit] = useState(userStore.isAdmin || userStore.isContentCreator);

    const linkToPage = () => {
        if (!canEdit) {
            history.push("/" + i18n.language + "/about")
        }
    }


    useEffect(() => {
        setCanEdit((userStore.isAdmin || userStore.isContentCreator));
    }, [userStore.isAdmin, userStore.isContentCreator]);

    return (<div className={'grid ui max-width'}>
        <div className={'row centered'}>
            <div className={'six wide computer fourteen wide mobile column'}>
                <section className={'imageview-padding-section'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <h5><EditTextComponent id={`${namespace}-sub-header-1`} namespace={namespace}/></h5>
                    <p><EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/></p>
                </section>
                <div className={'imageview-container'}>
                    <EditImageComponent linkTo={'about#ChambreRomantique'} id={'room-overview-6'}
                                        className={'imageview-image'}/>
                    <div className={'image-text'} onClick={linkToPage}>
                        <h2><EditTextComponent id={`${namespace}-image-header-6`} namespace={namespace}/></h2>
                        <p style={{color: 'white'}}><EditTextComponent id={`${namespace}-image-text-6`}
                                                                       namespace={namespace}/></p>
                    </div>
                </div>
                <div className={'imageview-container'} style={{marginTop: "2rem"}}>
                    <EditImageComponent linkTo={'about#LesDeuxLions'} id={'room-overview-1'}
                                        className={'imageview-image'}/>
                    <div className={'image-text'} onClick={linkToPage}>
                        <h2><EditTextComponent id={`${namespace}-image-header-1`} namespace={namespace}/></h2>
                        <p style={{color: 'white'}}><EditTextComponent id={`${namespace}-image-text-1`}
                                                                       namespace={namespace}/></p>
                    </div>

                </div>
            </div>
            <div className={'six wide computer fourteen wide mobile column'}>
                <div className={'imageview-container'}>
                    <EditImageComponent className={'imageview-image'} linkTo={'about#Bellevue'} id={'room-overview-4'}/>
                    <div className={'image-text'} onClick={linkToPage}>
                        <h2><EditTextComponent id={`${namespace}-image-header-4`} namespace={namespace}/></h2>
                        <p style={{color: 'white'}}><EditTextComponent id={`${namespace}-image-text-4`}
                                                                       namespace={namespace}/></p>
                    </div>
                </div>
                <div className={'imageview-container'}>
                    <EditImageComponent linkTo={'about#LouisXIV'} id={'room-overview-5'}
                                        className={'imageview-image'}/>
                    <div className={'image-text'} onClick={linkToPage}>
                        <h2><EditTextComponent id={`${namespace}-image-header-5`} namespace={namespace}/></h2>
                        <p style={{color: 'white'}}><EditTextComponent id={`${namespace}-image-text-5`}
                                                                       namespace={namespace}/></p>
                    </div>
                </div>
                <div className={'imageview-container'}>
                    <EditImageComponent linkTo={'about#Napoléon'} id={'room-overview-2'}
                                        className={'imageview-image'}/>
                    <div className={'image-text'} onClick={linkToPage}>
                        <h2><EditTextComponent id={`${namespace}-image-header-2`} namespace={namespace}/></h2>
                        <p style={{color: 'white'}}><EditTextComponent id={`${namespace}-image-text-2`}
                                                                       namespace={namespace}/></p>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

ChateauImageView.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'ImageView').then((text) => {
        return text
    })
}

export default withTranslation()(ChateauImageView)