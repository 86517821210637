import React from "react";
import {withTranslation} from "react-i18next";

import HomePage from "../userContent/userPages/HomePage/HomePage";


const Home = () => {
    return (
        <HomePage/>
    )
}

export default withTranslation()(Home)